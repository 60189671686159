import React from "react";
import SwiperCore from "swiper";
import { Navigation, Pagination } from "swiper/modules";
import Image from "next/image"
import Link from "next/link";
import { track } from "@vercel/analytics/react";
import { Header } from "../modules/consignment";
import { Footer } from "../modules/landing/components/footer";
import { SEO } from "../components/seo/seo";
import Testimonials from '../components/testimonial/testimonial';
import FloatingButton from '../components/floating-button/floating-button'
import { Card } from "@/components/ui/card";

SwiperCore.use([Pagination, Navigation]);


const featuredLogos = [
  { name: 'jakartapost', src: '/img/common/jakarta-post.png' },
  { name: 'techinasia', src: '/img/common/techinasia.png' },
  { name: 'kompas', src: '/img/common/kompas.png' },
  { name: 'kontan', src: '/img/common/kontan.png' },
  { name: 'bisnis', src: '/img/common/bisnis.png' },
]

export default function Index() {
  return (
    <main className="flex flex-col justify-between bg-white">
      <SEO title="Moora" path="/" description="Mulai cuanmu sekarang" />
      <Header />
      <section className="background-main-style bg-gradient-to-br from-white to-red-100 py-10">

        <div className="max-w-6xl mx-auto text-center px-4 sm:px-6 lg:px-8 background-main-kelas">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-6">
            <div className="relative h-[400px] p-6">
              <Image
                src="/img/common/landingai.svg"
                alt="Ilustrasi tools AI"
                layout="fill"
                objectFit="contain"
              />
            </div>
            <div className="p-6">
              <div className="text-left">
              </div>
              <h1 className="text-3xl font-bold text-gray-900 text-left mb-12">
                Barang Custom untuk Penjual
              </h1>
              <p className="text-md text-gray-600 text-left mb-12">
                Jadi yang pertama mencoba kemudahan berjualan menggunakan asisten virtual Jooalan.
              </p>
              <Link href="https://app.jooalan.id" className="flex text-left">
                <button
                  type="submit"
                  onClick={() => {
                    track('click_register');
                  }}
                  className="button-style text-lg bg-red-600 text-white px-6 py-2 rounded-r-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                >
                  COBA SEKARANG
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-red-900 py-16 background-style relative h-400 w-full px-6">
        <Card className="col-span-2 background-white-style">
          <h2 className="text-3xl font-bold text-center my-8 text-black">Solusi Sourcing & Dropship</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-6">
            <div className="relative h-[400px] p-6">
              <Image
                src="/img/common/mockups1.png"
                alt="Solusi 1"
                layout="fill"
                objectFit="contain"
              />
            </div>
            <div className="p-6">
              <h1 className="text-2xl font-bold text-black text-left mb-12">
                1000+ Barang Harga Tangan Pertama
              </h1>
              <p className="text-md text-black text-left mb-12">
                Temukan barang yang bisa kamu jual secara DROPSHIP. Beragam kategori barang disediakan untuk kamu jual. Harga dijamin tangan pertama!
              </p>
              <h1 className="text-2xl font-bold text-black text-left mb-12">
                Resi Otomatis
              </h1>
              <p className="text-md text-black text-left mb-12">
                Nomor resi otomatis dari marketplace bisa dicantumkan pada pesanan dropship kamu. Kirim barang ke pelanggan dengan mudah!
              </p>
            </div>
          </div>
        </Card>
      </section>

      <section className="background-main-style py-16 relative h-400 w-full px-6">
        <h2 className="text-3xl font-bold text-center mb-8">Buat Link Toko Online</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-6">

          <div className="p-6">
            <h1 className="text-2xl font-bold text-gray-900 text-left mb-12">
              Buat Landing Page dalam Hitungan Menit
            </h1>
            <p className="text-md text-gray-600 text-left mb-12">
              Tidak perlu coding! Buat landing page dengan mudah dan bisa langsung jualan
            </p>
            <h1 className="text-2xl font-bold text-gray-900 text-left mb-12">
              Konversi Tinggi & Terhubung dengan Semua Platform Favorit
            </h1>
            <p className="text-md text-gray-600 text-left mb-12">
              Desain responsif dan elemen yang sudah teruji untuk meningkatkan penjualan dan leads.
            </p>
          </div>

          <div className="relative h-[400px] p-6">
            <Image
              src="/img/common/mockups2.png"
              alt="Solusi 2"
              layout="fill"
              objectFit="contain"
            />
          </div>
        </div>
      </section>

      <section className="bg-red-900 py-16 background-style relative h-400 w-full px-6">
        <Card className="col-span-2 background-white-style">
          <h2 className="text-3xl font-bold text-center my-8 text-black">Tools AI Untuk Konten Produk</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-6">
            <div className="relative h-[400px] p-6">
              <Image
                src="/img/common/mockups3.png"
                alt="Solusi 3"
                layout="fill"
                objectFit="contain"
              />
            </div>
            <div className="p-6">
              <h1 className="text-2xl font-bold text-black text-left mb-12">
                Ubah Latar Foto Produk
              </h1>
              <p className="text-md text-gray-600 text-left mb-12">
                Artificial Intelligence (AI) Jooalan membantu modifikasi latar belakang hingga pencahayaan foto produk. Buat foto produk kamu beda dari penjual lain dengan MUDAH!
              </p>
              <h1 className="text-2xl font-bold text-black text-left mb-12">
                Buat Judul & Deskripsi Produk Otomatis
              </h1>
              <p className="text-md text-gray-600 text-left mb-12">
                Artificial Intelligence (AI) Jooalan menyusun kata-kata untuk judul dan deskripsi produk jualan Anda. Tidak perlu repot cari kata-kata lagi!
              </p>
            </div>
          </div>
        </Card>
      </section>

      <section className="bg-red-900 py-16 background-style relative h-400 w-full px-6">
        <Card className="col-span-2 background-white-style">
          <h2 className="text-3xl font-bold text-center my-8 text-black">Analisis Produk Marketplace</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-6">
            <div className="relative h-[400px] p-6">
              <Image
                src="/img/common/mockups4.png"
                alt="Solusi 4"
                layout="fill"
                objectFit="contain"
              />
            </div>
            <div className="p-6">
              <h1 className="text-2xl font-bold text-gray-900 text-left mb-12 text-black">
                Temukan Produk Terlaris dari Marketplace Nasional dan Internasional
              </h1>
              <div className="grid md:grid-cols-3 gap-8">
                <div className="flex flex-col items-center text-center">
                  <Image alt="1688" src="/img/common/1688.png" width="150" height="150" className="rounded-3xl" />
                </div>

                <div className="flex flex-col items-center text-center">
                  <Image alt="temu" src="/img/common/temu.png" width="150" height="150" />
                </div>

                <div className="flex flex-col items-center text-center">
                  <Image alt="shopee" src="/img/common/shopee1.png" width="150" height="150" />
                </div>
              </div>
            </div>
          </div>
        </Card>
      </section>

      <section className="background-main-style py-16 relative h-400 w-full">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-center mb-8">Tentang Kami</h2>
          <div className="grid gap-8 items-start">
            <div className="relative mb-8 lg:text-lg sm:text-md">
              <p className="">
                Jooalan hadir untuk membantu siapa saja yang ingin berjualan (online atau offline) untuk mulai berjualan. Jooalan berawal dari layanan belanja grosir online yang telah digunakan oleh 5000+ penjual. Kini Jooalan menghadirkan Asisten Virtual berbasis Artificial Intelligence (AI) serta pusat edukasi penjual lewat #KelasCuan.
              </p>
              <div className="relative pb-[56.25%] h-0 w-full mx-auto mt-8 flex justify-center">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/videoseries?si=PIZQ-6zRw11okyTO&amp;list=PLvn6cE595aTMZkVyyERZQpIy7nHNZ01Kv"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  className="absolute size-[90%]"
                  allowFullScreen>
                </iframe>
              </div>
              <h2 className="text-3xl font-bold text-center text-gray-900 mb-8 mt-4">
                Diliput Oleh
              </h2>
              <div className="flex flex-wrap justify-center items-center gap-8 md:gap-12">
                {featuredLogos.map((logo) => (
                  <div key={logo.name} className="flex items-center justify-center">
                    <Image
                      src={logo.src}
                      alt={`${logo.name} logo`}
                      width={150}
                      height={30}
                      className="max-h-8 w-auto"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <Testimonials />
        </div>
      </section>

      <FloatingButton />
      <Footer />
    </main>
  );
}
